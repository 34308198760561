<template>
  <ClientOnly>
    <div
      v-if="showAdminTools"
      class="relative mx-auto hidden w-full max-w-6xl md:block"
    >
      <nuxt-link
        v-if="url"
        class="absolute right-0 top-0 mr-6 cursor-pointer select-none rounded-b-lg bg-gradient-to-b from-[#0061ff] via-[#0054dd] to-[#0054dd] px-3 py-0.5 font-medium text-white shadow-drop-lg"
        :to="url"
        rel="nofollow"
        target="_blank"
        title="Edit"
        >✏️
      </nuxt-link>
    </div>
  </ClientOnly>
</template>

<script setup lang="ts">
import { getCookie } from '@/utils/cookie-utils';

const props = defineProps<{
  id: string;
  typeHandle: string;
}>();

const config = useRuntimeConfig();

const { cmsSiteHandle } = useBaseCountryConfig();

const showAdminTools = ref(false);

const url = `${config.public.editorialApiBaseUrl}/admin/entries/${props?.typeHandle}/${props?.id}?site=${cmsSiteHandle}`;

onMounted(() => {
  showAdminTools.value =
    getCookie(document.cookie, 'show_admin_tools') === 'yes';
});
</script>
